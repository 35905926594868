@import "../index.scss";

#bookSection {
  width: 100%;
  border-bottom: 0.2rem solid $primary-orange;
  display: flex;
  align-items: center;
  justify-content: center;

  .title-and-button-container {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      margin: 0.6rem;
      font-size: 24px;
      line-height: normal;
      text-align: center;
    }
  }

  .book-button {
    @include success-button();
  }
}
