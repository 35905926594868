@import "../index.scss";

#discoverSection {
  @include section-border-bottom-thin();
  background-color: $primary-yellow;

  .card {
    border-color: $primary-orange !important;
    border-width: thin;
    img {
      height: 200px;
    }
  }
}
