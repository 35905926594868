@mixin success-button {
  margin: 8px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  text-align: center;
  background-color: $secondary-green;
  font-weight: 600;
  &:hover {
    background-color: $primary-green;
  }
}
