$primary-orange: #e6ac00;

$primary-green: #092009;
$secondary-green: #185d18;

$primary-yellow: #fff7e6;
$secondary-yellow: #fffcf5; //footer and header

$primary-black: black;
$secondary-black: rgb(61, 61, 61);
