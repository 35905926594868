@import url("https://fonts.googleapis.com/css?family=Quicksand&display=swap");
@import url("https://fonts.googleapis.com/css?family=Belleza|Quicksand&display=swap");
@import "./styles/color-variables.scss";
@import "./styles/buttons.scss";

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  font-family: "Quicksand", sans-serif !important;
}

@mixin section-border-bottom-thin {
  border-bottom: 0.1rem solid $primary-orange;
  padding-bottom: 3rem;
}
