@import ".././index.scss";

@mixin dropdown-item-selected {
  font-weight: bold;
  background-color: $primary-orange;
  color: $primary-black;
}

#top-navbar {
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  font-size: 20px;
  background: $secondary-yellow;
  border-bottom: 0.3rem solid $primary-orange;

  .navbar-brand {
    color: $primary-black;
  }

  .dropdown {
    color: $secondary-black;

    #basic-nav-dropdown {
      display: flex;
      align-items: center;
    }

    span {
      font-size: 16px;
      font-weight: 400;
    }

    .dropdown-item-selected {
      @include dropdown-item-selected();
    }

    @media (min-width: 992px), (max-width: 768px) {
      .dropdown-menu {
        position: absolute;
        left: -40px;
      }
    }

    .dropdown-item.active,
    .dropdown-item:active {
      @include dropdown-item-selected();
    }

    .dropdown-toggle::after {
      display: none !important;
    }
  }

  .nav-link {
    color: $primary-black;
    padding-right: 12px;
    padding-left: 12px;
  }
}
