@import "../index.scss";

footer {
  color: $secondary-black;
  border-top: 0.2rem solid $primary-orange;
  background: $secondary-yellow;
  flex-shrink: 0;
  a {
    color: $secondary-black;
    text-decoration: none;
    &:hover {
      color: $secondary-black;
    }
  }
}
