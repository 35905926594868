@import "../index.scss";

#roomsSection {
  @include section-border-bottom-thin();
  background-color: $primary-yellow;
  .carousel-container {
    display: flex;
    justify-content: center;
    .carousel {
      max-width: 700px;
    }
  }
}
